import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

if (typeof window !== 'undefined') {
    console.log('NODE_ENV:', process.env.NODE_ENV);
    console.log('PostHog Key:', process.env.REACT_APP_PUBLIC_POSTHOG_KEY);
    console.log('PostHog Host:', process.env.REACT_APP_PUBLIC_POSTHOG_HOST);

    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        person_profiles: 'identified_only',
        capture_pageview: false, // Disable automatic page view capture, as we capture manually see AnalyticsPageView component
        capture_pageleave: true, // Enable automatic page leave capture
        persistence: 'localStorage',
        // persistence: hasGivenConsent ? 'localStorage+cookie' : 'memory',
    })
    posthog.debug()
}

export function AnalyticsProvider({ children }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
